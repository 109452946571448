<template>
  <div class="server-group">
    <div class="server-group-label">
      <span class="left">
        <img :src="icon" alt="" aria-hidden="true">
        <span>{{group.name}}</span>
      </span>
      <span class="right"></span>
    </div>

    <template v-if="servers && servers.length > 0">
      <div v-for="server in servers" :key="server.id">
        <TextModerationServer v-if="server.type === 'TEXT_MODERATION'" :server="server"/>
        <ImageModerationServer v-else-if="server.type === 'IMAGE_MODERATION'" :server="server"/>
      </div>
    </template>
    <div v-else class="server-group-empty-state">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import TextModerationServer from "./servers/TextModerationServer";
  import ImageModerationServer from "./servers/ImageModerationServer";

  import textIcon from "@/assets/icons/text.svg";
  import imageIcon from "@/assets/icons/image.svg";

  export default {
    name: "ServerGroup",
    components: {ImageModerationServer, TextModerationServer},
    props: {
      group: {
        type: Object,
        required: true
      },
      servers: {
        type: Array,
        required: true,
      },
    },
    computed: {
      icon() {
        const iconLUT = {
          'TEXT_MODERATION': textIcon,
          'IMAGE_MODERATION': imageIcon
        };

        return iconLUT[this.group.type];
      }
    },
  }
</script>

<style lang="scss">
  .server-group {
    max-width: 800px;
    margin: 0 auto 40px;

    &-label {
      margin: 0 0 0 9px;
      display: flex;
      user-select: none;
      font-weight: 500;
      color: rgba(#000, 0.8);

      .left {
        display: flex;
        align-items: center;
        padding: 10px 0 6px 16px;
        background: url("../../../assets/server-group-label.svg");

        > img {
          margin: 0 8px 0 0;
        }
      }

      .right {
        width: 40px;
        background: url("../../../assets/server-group-label.svg") top right;
      }
    }

    &-empty-state {
      user-select: none;
      background: #F7F6F9;
      //box-shadow: inset 0 0 1px 0 rgba(#000, 0.2);
      border: 1px solid rgba(#000, 0.05);
      border-radius: 6px;
      padding: 24px;
      color: rgba(#000, 0.4);
    }
  }
</style>
