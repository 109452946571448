<template>
  <span class="server-led" :class="className" :style="{'animation-delay': animationDelay}"></span>
</template>

<script>
  export default {
    name: 'ServerLED',
    computed: {
      animationDelay() {
        const delay = Math.round(Math.random() * 1500);
        return `${delay}ms`;
      },
      className() {
        const number = 1 + Math.round(Math.random() * 2);
        return `server-led-${number}`;
      }
    }
  }
</script>

<style lang="scss">
  .server-led {
    display: inline-block;
    background: #1D1D1D;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin: 0 5px;

    &-1 {
      animation:4s server-led-sequence-1 infinite step-end;
    }

    &-2 {
      animation:4s server-led-sequence-2 infinite step-end;
    }

    &-3 {
      animation:4s server-led-sequence-3 infinite step-end;
    }
  }

  @keyframes server-led-sequence-1 {
    0%  { background: #59E678; } // on
    15% { background: #1D1D1D; } // off
    20% { background: #59E678; } // on
    35% { background: #1D1D1D; } // off
    40% { background: #59E678; } // on
    75% { background: #1D1D1D; } // off
    85% { background: #59E678; } // on
    90% { background: #1D1D1D; } // off
  }

  @keyframes server-led-sequence-2 {
    0%  { background: #59E678; } // on
    15% { background: #1D1D1D; } // off
    20% { background: #59E678; } // on
    25% { background: #1D1D1D; } // off
    30% { background: #59E678; } // on
    45% { background: #1D1D1D; } // off
    50% { background: #59E678; } // on
    90% { background: #1D1D1D; } // off
  }

  @keyframes server-led-sequence-3 {
    0%  { background: #59E678; } // on
    20% { background: #1D1D1D; } // off
    25% { background: #59E678; } // on
    50% { background: #1D1D1D; } // off
    55% { background: #59E678; } // on
  }
</style>
