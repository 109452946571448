<template>
  <header>
    <NavBar :account="account"></NavBar>
    <AlertBar v-if="account.status == 'past_due'">
      The payment for your current invoice has failed. <router-link :to="{name: 'billing'}">Please update your payment information</router-link>
      or contact your bank to avoid service interruptions.
    </AlertBar>
    <AlertBar v-else-if="account.status == 'unpaid'">
      Your account has been suspended for non payment. <router-link :to="{name: 'billing'}">Update your payment information</router-link>
      to continue using the service.
    </AlertBar>
    <AlertBar v-else-if="showTrialEndedAlert">
      Your free trial has ended. <router-link :to="{name: 'plans'}">Pick a plan</router-link>
      to continue using the service.
    </AlertBar>
  </header>
</template>

<script>
  import NavBar from "./NavBar";
  import AlertBar from "./AlertBar";
  export default {
    name: "Header",
    components: {AlertBar, NavBar},
    props: {
      account: {
        type: Object,
        required: true
      },
    },
    computed: {
      trial() {
        return this.account === null ? null : this.account.trial;
      },
      showTrialEndedAlert() {
        return this.trial !== null && this.trial.daysLeft === 0;
      }
    },
  }
</script>
