<template>
  <div class="server-profile-scanner">
    <svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <rect id="Rectangle" fill="#1F692B" x="0" y="0" width="80" height="80"></rect>
      <path d="M15,80 L14,80 L14,77 L0,77 L0,76 L14,76 L14,68 L0,68 L0,67 L14,67 L14,59 L0,59 L0,58 L14,58 L14,50 L0,50 L0,49 L14,49 L14,41 L0,41 L0,40 L14,40 L14,32 L0,32 L0,31 L14,31 L14,23 L0,23 L0,22 L14,22 L14,14 L0,14 L0,13 L14,13 L14,5 L0,5 L0,4 L14,4 L14,0 L15,0 L15,4 L27,4 L27,0 L28,0 L28,4 L40,4 L40,0 L41,0 L41,4 L53,4 L53,0 L54,0 L54,4 L66,4 L66,0 L67,0 L67,4 L80,4 L80,5 L67,5 L67,13 L80,13 L80,14 L67,14 L67,22 L80,22 L80,23 L67,23 L67,31 L80,31 L80,32 L67,32 L67,40 L80,40 L80,41 L67,41 L67,49 L80,49 L80,50 L67,50 L67,58 L80,58 L80,59 L67,59 L67,67 L80,67 L80,68 L67,68 L67,76 L80,76 L80,77 L67,77 L67,80 L66,80 L66,77 L54,77 L54,80 L53,80 L53,77 L41,77 L41,80 L40,80 L40,77 L28,77 L28,80 L27,80 L27,77 L15,77 L15,80 Z M15,76 L27,76 L27,68 L15,68 L15,76 Z M40,68 L28,68 L28,76 L40,76 L40,68 Z M66,68 L54,68 L54,76 L66,76 L66,68 Z M53,68 L41,68 L41,76 L53,76 L53,68 Z M15,67 L27,67 L27,59 L15,59 L15,67 Z M40,59 L28,59 L28,67 L40,67 L40,59 Z M66,59 L54,59 L54,67 L66,67 L66,59 Z M53,59 L41,59 L41,67 L53,67 L53,59 Z M15,58 L27,58 L27,50 L15,50 L15,58 Z M66,50 L54,50 L54,58 L66,58 L66,50 Z M40,50 L28,50 L28,58 L40,58 L40,50 Z M53,50 L41,50 L41,58 L53,58 L53,50 Z M15,49 L27,49 L27,41 L15,41 L15,49 Z M40,41 L28,41 L28,49 L40,49 L40,41 Z M66,41 L54,41 L54,49 L66,49 L66,41 Z M53,41 L41,41 L41,49 L53,49 L53,41 Z M15,40 L27,40 L27,32 L15,32 L15,40 Z M40,32 L28,32 L28,40 L40,40 L40,32 Z M66,32 L54,32 L54,40 L66,40 L66,32 Z M53,32 L41,32 L41,40 L53,40 L53,32 Z M15,31 L27,31 L27,23 L15,23 L15,31 Z M40,23 L28,23 L28,31 L40,31 L40,23 Z M66,23 L54,23 L54,31 L66,31 L66,23 Z M53,23 L41,23 L41,31 L53,31 L53,23 Z M15,22 L27,22 L27,14 L15,14 L15,22 Z M53,14 L41,14 L41,22 L53,22 L53,14 Z M66,14 L54,14 L54,22 L66,22 L66,14 Z M40,14 L28,14 L28,22 L40,22 L40,14 Z M15,13 L27,13 L27,5 L15,5 L15,13 Z M53,5 L41,5 L41,13 L53,13 L53,5 Z M40,5 L28,5 L28,13 L40,13 L40,5 Z M66,5 L54,5 L54,13 L66,13 L66,5 Z" id="Combined-Shape" fill="#2DB92E" opacity="0.521647135"></path>
      <path class="server-profile-scanner-silhouette" d="M13,70.5724299 C13,64.3014019 13.9974555,60.453271 15.9923664,59.0280374 C18.9847328,56.8901869 30.5267176,56.1775701 31.9516539,55.1799065 C32.9016115,54.5147975 33.3765903,52.6619938 33.3765903,49.6214953 C31.4426541,48.0691256 30.1602113,46.7864153 29.5292621,45.7733645 C28.8983129,44.7603136 28.3758362,43.1450488 27.9618321,40.9275701 C25.1119593,39.2172897 23.5920271,37.2219626 23.4020356,34.9415888 C23.2120441,32.661215 23.9720102,30.9509346 25.6819338,29.8107477 C24.1266315,26.0517151 23.5091591,23.0587244 23.8295165,20.8317757 C24.149874,18.604827 25.717304,14.9942351 28.5318066,10 C30.6217133,10.5700935 32.71162,10.8551402 34.8015267,10.8551402 C36.8914334,10.8551402 40.5487701,10.5700935 45.7735369,10 C47.6742977,10.0008458 49.0042383,10.3809081 49.7633588,11.1401869 C50.5224793,11.8994658 51.3299432,13.6572539 52.1857506,16.4135514 C55.2256149,17.173676 57.0305344,18.5514019 57.6005089,20.546729 C58.1704835,22.5420561 57.5055131,25.6300623 55.605598,29.8107477 C57.6955047,30.7609034 58.5979644,32.4711838 58.3129771,34.9415888 C58.0279898,37.4119938 56.6505513,39.3123053 54.1806616,40.6425234 C53.2241759,43.3108679 52.416712,45.1636717 51.7582697,46.2009346 C51.0998275,47.2381975 50.0073762,48.3783844 48.480916,49.6214953 C48.5212438,51.6950035 48.6162396,53.0727293 48.7659033,53.7546729 C48.915567,54.4366165 49.2480522,55.0067099 49.7633588,55.4649533 C53.7629814,55.7903744 56.6603521,56.1229289 58.4554707,56.4626168 C61.1481487,56.9721487 64.4402036,57.8878505 65.437659,58.4579439 C66.4351145,59.0280374 67.1748468,59.7931357 67.8600509,62.3060748 C68.3168536,63.9813675 68.6968366,66.8793426 69,71 L13,70.5724299 Z" id="Path" stroke="#023906" fill-opacity="0.248169799" fill="#000000"></path>
      <rect class="server-profile-scanner-line" fill="#CADD03" x="0" y="49" width="80" height="1"></rect>
    </svg>
  </div>
</template>
<script>
  export default {
    name: "ServerProfileScanner"
  }
</script>
<style lang="scss">
  .server-profile-scanner {
    border-radius: 4px;
    overflow: hidden;
    line-height: 0;
    position: relative;

    &::after {
      content: "";
      box-shadow: inset -1px 1px 1px 0 rgba(#000, 0.40);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &-silhouette {
      opacity: 0;
      animation-name: server-profile-scanner-silhouette;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    &-line {
      animation-name: server-profile-scanner-line;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
    }

  }

  @keyframes server-profile-scanner-silhouette {
    0%   { opacity: 0.0; }
    40%  { opacity: 1.0; }
    50%  { opacity: 1.0; }
    90%  { opacity: 1.0; }
    100% { opacity: 0.0; }
  }

  @keyframes server-profile-scanner-line {
    0%   { y: 0; }
    100% { y: 80; }
  }
</style>
