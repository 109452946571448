<template>
  <div class="alert-bar">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "AlertBar"
  }
</script>

<style lang="scss">
  .alert-bar {
    color: #332923;
    background: #FFCEB3;
    text-align: center;
    padding: 16px;

    a {
      color: currentColor;
      font-weight: bold;
    }
  }
</style>
