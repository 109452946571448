<template>
  <footer class="footer">
    <div class="footer-wrapper">
      <div>
        <p>Made with <span aria-label="Love" class="footer-heart">♥</span> by MacroPoetry</p>
        <p class="copyright body-small">© 2020 MacroPoetry LLC. All rights reserved.</p>
      </div>

      <ul class="footer-links">
        <li><a href="mailto:support@profanitor.com">Help</a></li>
        <li><a href="https://profanitor.com/legal/terms-of-service/">Terms</a></li>
        <li><a href="https://profanitor.com/legal/privacy-policy/">Privacy</a></li>
      </ul>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "Footer"
  }
</script>

<style lang="scss">
  .footer {
    padding: 16px 16px;
    background-color: #fff;
    border-top: 1px solid rgba(#000, 0.05);

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1024px;
      margin: 0 auto;
    }

    p {
      margin: 0 0 4px;
    }

    .copyright {
      color: rgba(#000, 0.6);
    }

    &-heart {
      color: #FF5C00;
      font-size: 20px;
    }

    &-links {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      li:not(:last-child) {
        margin: 0 16px 0 0;
      }

      a {
        color: #6F7272;
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid transparent;

        &:hover {
          color: #6F7272;
          text-decoration: none;
          border-bottom: 2px solid #6F7272;
        }
      }
    }
  }
</style>
