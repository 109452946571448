<template>
  <div class="dashboard-layout">
    <Header v-if="account" :account="account"></Header>
    <div class="content">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import Footer from "../components/Footer";
  
  export default {
    components: {Footer, Header},
    props: {
      account: {
        type: Object,
        required: false
      },
    },
  }
</script>

<style lang="scss">
  .dashboard-layout {
    min-width: 720px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > .content {
      flex: 1 1 0;
    }
  }
</style>
