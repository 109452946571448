<template>
  <div class="new-server-screen">
    <p class="breadcrumbs">
      <router-link to="/dashboard">Back to my servers</router-link>
    </p>
    <div class="new-server-screen-box">
      <div class="new-server-header">
        <h2>Create new server</h2>
        <p class="body-large">Use servers to scope your moderation models for different projects and environments. <br>
           Each server uses a unique API key and can have its own custom settings. </p>
      </div>

      <form @submit.prevent="handleSubmit">
        <div class="form-row">
          <h3 class="heading-5"><label for="server-type">Select server type</label></h3>
          <select id="server-type" class="select" required v-model="type">
            <option value="TEXT_MODERATION">Text Moderation</option>
            <option value="IMAGE_MODERATION">Image Moderation</option>
          </select>
        </div>

        <div class="form-row">
          <div v-if="type === 'IMAGE_MODERATION'">
            <h3 class="heading-5">Select all models you are interested in...</h3>
            <div class="form-row-subrow" v-for="category in imageModerationCategories" :key="category.id">
<!--              <p>{{category.name}}:</p>-->
              <PModelPicker v-for="model in category.models" :key="model.id" :value="model.id" v-model="imageModerationModels">
                {{model.name}}
              </PModelPicker>
            </div>
          </div>
        </div>

        <div class="form-row">
          <h3 class="heading-5"><label for="server-name">Name your server</label></h3>
          <PFormField hint="A concise name for your app, e.g. Awesome App - Production">
            <PTextField id="server-name" class="text-field-large" required maxlength="32" v-model="name"></PTextField>
          </PFormField>
        </div>

        <div class="form-row">
          <PButton type="submit" :loading="saving" :disabled="saving">Create Server</PButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import PButton from "@/components/PButton";
  import PTextField from "@/components/PTextField";
  import PModelPicker from "@/components/PModelPicker";
  import {CREATE_IMAGE_MODERATION_SERVER, CREATE_SERVER} from "./mutations";
  import {GET_IMAGE_MODERATION_CATEGORIES} from "./queries";
  import PFormField from "@/components/PFormField";

  export default {
    name: "NewServerScreen",
    components: {PFormField, PModelPicker, PTextField, PButton},
    data () {
      return {
        type: '',
        name: '',
        imageModerationModels: [],
        imageModerationCategories: [],
        saving: false,
      }
    },
    apollo: {
      imageModerationCategories: GET_IMAGE_MODERATION_CATEGORIES
    },
    methods: {
      async handleSubmit() {
        this.saving = true;

        try {
          switch (this.type) {
            case 'IMAGE_MODERATION':
              await this.createImageModerationServer();
              break;
            default:
              await this.createServer();
              break;
          }
        } catch (e) {
          console.log(e);
        }

        await this.$router.push("/dashboard");

        this.saving = false;
      },
      createServer() {
        return this.$apollo.mutate({
          mutation: CREATE_SERVER,
          variables: {
            type: this.type,
            name: this.name
          }
        });
      },
      createImageModerationServer() {
        return this.$apollo.mutate({
          mutation: CREATE_IMAGE_MODERATION_SERVER,
          variables: {
            name: this.name,
            modelIds: this.imageModerationModels
          }
        });
      }
    }
  }
</script>

<style lang="scss">
  @import "../../scss/variables";

  .new-server-screen {
    max-width: 960px;
    margin: 32px auto;
    padding: 0 16px;

    &-box {
      box-shadow: 0 0 4px rgba(#000, 0.1);
      border-radius: 6px;
      padding: 40px 64px;
      background: #fff;
    }

    .new-server-header {
      margin-bottom: 40px;
    }

    .form-row {
      margin: 24px 0;

      h3 {
        margin: 12px 0;
      }

      select {
        height: 48px;
        font-size: 18px;
        width: 320px;
        font-family: $family-sans-serif;
      }

      .text-field-large {
        width: 320px;
      }
    }

    .picker {
      margin: 4px 8px 4px 0;
    }
  }
</style>
