function round(number, decimalPlaces) {
  const truncated = Number((Math.floor(number * 10) / 10).toFixed(decimalPlaces));

  if (truncated % 1 === 0) {
    return truncated.toFixed(0);
  }

  return truncated.toFixed(1);
}


function abbreviateNumber(number) {
  if (typeof number !== 'number') {
    number = parseInt(number, 10);
    if (isNaN(number)) {
      number = 0;
    }
  }

  if (Math.abs(number) >= 1000000) {
    return round(number / 1000000.0, 1) + "M";
  }

  if (Math.abs(number) >= 1000) {
    return round(number / 1000.0, 1) + "k";
  }

  return number.toString();
}

export {
  round,
  abbreviateNumber
};

