<template>
  <router-link class="server" :to="url">
    <slot name="led">
      <div class="server-segment">
        <div>
          <ServerLED/>
          <ServerLED/>
        </div>
      </div>
    </slot>

    <slot name="specialized-part"></slot>

    <div class="server-segment server-name-label">
      <span><slot></slot></span>
    </div>

    <div class="server-segment">
      <ServerLCD :operations="server.operationsToday"/>
    </div>

    <div class="server-segment">
      <img width="32" height="32" src="@/assets/server-settings-button.svg" alt="Settings">
    </div>
  </router-link>
</template>

<script>
  import ServerLED from "@/screens/servers/components/parts/ServerLED";
  import ServerLCD from "@/screens/servers/components/parts/ServerLCD";

  export default {
    name: 'BaseServer',
    components: {ServerLED, ServerLCD},
    props: {
      server: {
        type: Object,
        required: true
      }
    },
    computed: {
      url () {
        return {
          name: 'server-details.dashboard',
          params: {
            server_id: this.server.id
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  .server {
    text-decoration: none;
    max-width: 800px;
    box-sizing: border-box;
    padding: 8px 8px;
    margin: 0 auto;
    height: 80px;
    background-image: linear-gradient(180deg, #3E4747 0%, #2B3131 100%);
    box-shadow: inset -2px 2px 4px 0 rgba(#FFF, 0.1), inset 0 -2px 0 0 rgba(#000, 0.1);
    border-radius: 6px;
    display: flex;
    user-select: none;

    &:hover {
      cursor: pointer;
      background-image: linear-gradient(180deg, lighten(#3E4747, 5) 0%, lighten(#2B3131, 5) 100%);
    }

    &-segment {
      margin: 0 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-name-label {
      color: #fff;
      flex: 1;
      padding: 2px 4px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
    }

    &-settings-button {
      color: #ffffff;
      background: #6F7272;
      box-shadow: 0 0 2px 0 rgba(#000, 0.5), inset 0 1px 0 0 rgba(#fff, 0.2);
      border-radius: 6px;
      padding: 8px 8px;
    }
  }
</style>
