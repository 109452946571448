<template>
  <div class="user-nav-dropdown">
    <ul>
      <li><router-link :to="{name: 'account-settings'}">Account</router-link></li>
      <li class="has-separator"><router-link :to="{name: 'billing'}">Plans &amp; Billing</router-link></li>
      <li><a @click.prevent="logout" href="/">Logout</a></li>
    </ul>
  </div>
</template>

<script>
  import {apolloClient} from "@/apollo";
  export default {
    created() {
      console.log('created');
      document.addEventListener('click', this.onClickOutside);
    },

    destroyed() {
      console.log('destroyed');
      document.removeEventListener('click', this.onClickOutside)
    },

    methods: {
      onClickOutside(event) {
        if (event.target !== this.$el /* && !this.$el.contains(event.target) */) {
          this.$emit('click-outside');
        }
      },
      async logout() {
        try {
          await this.$api.logout();
          await apolloClient.resetStore();
          await this.$router.push('/');
        } catch (e) {
          console.log(e);
        }
      }
    },
  }
</script>

<style lang="scss">
  @import "../../scss/variables";

  .user-nav-dropdown {
    position: absolute;
    top: 44px;
    right: 0;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba(#000, 0.2);
    min-width: 180px;
    border-radius: 4px;
    margin: 0;
    padding: 0;

    ul {
      padding: 16px 0;
      margin: 0;
      list-style: none;
    }

    li.has-separator::after {
      content: '';
      position: relative;
      display: block;
      margin: 6px 20px;
      border-bottom: 1px solid #dddddd;
    }

    a {
      color: #000;
      padding: 12px 20px;
      display: block;
      text-decoration: none;
    }

    a:hover {
      background: #f2f1f4;
      color: #000;
    }
  }
</style>
