<template>
  <div class="nav-bar">
    <div class="inner">
      <router-link to="/dashboard">
        <img class="logo" src="@/assets/logo-dark.svg" alt="Profanitor">
      </router-link>

      <div class="user-nav" v-if="account">
        <span v-if="account.trial && account.trial.daysLeft > 0">
          <router-link class="is-alert" :to="{name: 'billing'}">
            {{account.trial.daysLeft}} {{plural(account.trial.daysLeft, 'day', 'days')}} left in your trial
          </router-link>
        </span>

        <span>
          <router-link class="nav-link" :to="{name: 'dashboard'}">
            <span>Servers</span>
          </router-link>
        </span>

        <span>
          <a class="nav-link" href="https://profanitor.com/docs/" target="_blank">
            <span>Documentation</span>
          </a>
        </span>

        <button tabindex="0" role="button" aria-label="Account Menu" class="profile-link" @click="toggleDropdown">
          <svg width="24" height="24" viewBox="0 0 24 24" v-bind:class="'icon profile-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 13c-1.142 0-2.773.611-4.893 1.834A2.212 2.212 0 006 17.75c0 .69.56 1.25 1.25 1.25h9.5c.69 0 1.25-.56 1.25-1.25 0-.79-.422-1.521-1.107-1.916C14.773 14.611 13.142 14 12 14zm0-9a4 4 0 100 8 4 4 0 000-8z"/></svg>
          {{account.firstName}}
          <svg width="11" height="7" viewBox="0 0 11 7" v-bind:class="'icon small-down-arrow-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M5.5 4.171L2.159 1.247A1 1 0 10.84 2.753l4 3.5a1 1 0 001.318 0l4-3.5A1 1 0 008.84 1.247L5.5 4.171z"/></svg>
        </button>
        <transition name="user-nav-dropdown-transition">
          <NavBarDropdown v-if="showDropdown" @click-outside="onClickOutside"></NavBarDropdown>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import NavBarDropdown from "./NavBarDropdown";

  export default {
    components: {
      NavBarDropdown
    },
    props: {
      account: {
        type: Object,
        required: false
      },
    },
    data() {
      return {
        showDropdown: false
      }
    },
    methods: {
      toggleDropdown(event) {
        event.target.blur();

        event.preventDefault();
        event.stopPropagation();

        this.showDropdown = !this.showDropdown;
      },

      onClickOutside() {
        this.showDropdown = false;
      },

      plural(count, singular, plural) {
        return count === 1 ? singular : plural;
      }
    },
  }
</script>

<style lang="scss">
  @import "@/scss/variables";

  .nav-bar {
    position: relative;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 100;

    .inner {
      margin: 0 auto;
      max-width: 1024px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .logo {
      line-height: 0;

      img {
        height: 24px;
      }
    }

    .user-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;

      > span, > button {
        margin: 0 8px;
        &:first-child { margin-left: 0; }
        &:last-child { margin-right: 0; }
      }

      a.nav-link {
        padding: 6px 8px;
        border-radius: 6px;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        color: #000000;

        &:hover {
          background: rgba(#000, 0.05);
          transition: background-color 0.1s ease-out;
        }

        svg {
          fill: rgba(#000, 0.6);
          margin: 0 8px 0 0;
        }
      }

      a.is-alert {
        background: rgba($alert-color-light90, 0.8);
        border-radius: 16px;
        padding: 6px 12px;
        margin-right: 12px;
      }
    }

    .profile-link {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      cursor: pointer;
      background: none;
      appearance: none;
      border: 0;
      font: inherit;

      &:hover, &:focus {
        outline: none;
        background: rgba(#000, 0.05);
        border-radius: 6px;
        transition: background-color 0.1s ease-out;
      }

      .icon { fill: rgba($brand-color-dark50, 0.3); }
      .profile-icon { margin: 0 6px 0 0; }
      .small-down-arrow-icon { margin: 0 0 0 4px; }
    }
  }

  .user-nav {
    position: relative;

    .profile-link {
      user-select: none;
      margin-right: 0!important;
    }
  }

  .user-nav-dropdown-transition {

    &-enter-active, &-leave-active {
      transition: all 0.1s;
      transform: initial;
    }

    &-enter, &-leave-to {
      opacity: 0;
      transform: translate3d(0, -12px, 0);
    }
  }

</style>
