<template>
  <BaseServer :server="server">
    {{server.name}}
  </BaseServer>
</template>

<script>
  import BaseServer from "./BaseServer";

  export default {
    name: "TextModerationServer",
    components: {BaseServer},
    props: {
      server: {
        type: Object,
        required: true
      }
    }
  }
</script>
