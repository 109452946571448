import gql from "graphql-tag/src";

const GET_SERVERS = gql`
  query GetServers {
    servers {
      id
      type
      name
      apiKey
      operationsToday
    }
  }
`;

export {
  GET_SERVERS
};
