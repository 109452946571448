<template>
  <div class="server-lcd">
    <strong>{{operations | abbreviateNumber}} </strong> <small>{{operations | pluralize('task', 'tasks')}} today</small>
  </div>
</template>

<script>
  import {pluralize} from "@/lib/text";
  import {abbreviateNumber} from "@/lib/number";

  export default {
    name: "ServerLCD",
    props: {
      operations: {
        type: Number,
        required: true
      },
    },
    filters: {
      abbreviateNumber,
      pluralize
    }
  }
</script>

<style lang="scss">
  .server-lcd {
    background: rgba(#1F692B, 0.5);
    box-shadow: inset -1px 1px 1px 0 rgba(#000, 0.40);
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
    min-width: 145px;
    box-sizing: border-box;
    text-align: center;

    small {
      font-size: 14px;
    }
  }
</style>
