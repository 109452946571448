import gql from "graphql-tag/src";

export const CREATE_SERVER = gql`
  mutation CreateServer($type: ServerType!, $name: String!) {
    createServer(type: $type, name: $name) {
      id
      type
      name
    }
  }
`;

export const CREATE_IMAGE_MODERATION_SERVER = gql`
  mutation CreateServer($name: String!, $modelIds: [ID!]!) {
    createImageModerationServer(name: $name, modelIds: $modelIds) {
      id
      type
      name
    }
  }
`;
