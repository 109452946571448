import gql from 'graphql-tag';


export const GET_IMAGE_MODERATION_CATEGORIES = gql`
  query GetImageModerationCategories {
    imageModerationCategories {
      id
      name
      models {
        id
        name
      }
    }
  }
`;
