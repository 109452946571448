<template>
  <BaseServer :server="server">
    <slot slot="specialized-part">
      <div class="server-segment server-segment-centered">
        <ServerProfileScanner/>
      </div>
    </slot>

    <slot>
      {{server.name}}
    </slot>
  </BaseServer>
</template>

<script>
  import BaseServer from "./BaseServer";
  import ServerProfileScanner from "../parts/ServerProfileScanner";

  export default {
    name: "ImageModerationServer",
    components: {BaseServer, ServerProfileScanner},
    props: {
      server: {
        type: Object,
        required: true
      }
    }
  }
</script>
