<template>
  <div class="dashboard">
    <div class="dashboard-header">
      <h1>
        <span class="heading-3">My Servers</span>
        <img src="@/assets/icons/servers-icon-color.svg" alt="Servers icon">
      </h1>

      <router-link to="/new-server" class="button is-medium">Create New Server</router-link>
    </div>

    <div class="dashboard-content">
      <ServerGroup v-for="group in groups" :key="group.type" :group="group" :servers="groupedServers[group.type]">
        No {{ group.name.toLowerCase() }} servers to show here.
      </ServerGroup>
    </div>
  </div>
</template>
<script>
  import {groupBy} from "lodash";
  import {GET_SERVERS} from "./queries.js";
  import ServerGroup from "./components/ServerGroup";

  export default {
    components: {ServerGroup},
    data () {
      return {
        groups: [
          {
            name: "Text Moderation",
            type: "TEXT_MODERATION",
          },
          {
            name: "Image Moderation",
            type: "IMAGE_MODERATION",
          },
        ],
        servers: []
      }
    },
    computed: {
      groupedServers() {
        const defaults = Object.assign({}, ...this.groups.map((g) => {
          return {[g.type]: []}
        }));

        return {
          ...defaults,
          ...groupBy(this.servers, 'type')
        };
      }
    },
    apollo: {
      servers: {
        query: GET_SERVERS
      }
    }
  }
</script>
<style lang="scss">
  .dashboard {
    //min-height: 498px;
    //padding: 0 16px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 116px;
      background-color: #f7f6f9;
      width: 100vw;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 32px 16px;
      margin: 0 auto;
      max-width: 800px;

      h1 {
        margin: 0;
        line-height: 1em;
        display: flex;
        align-items: center;
      }

      h1 span {
        margin: 0 16px 0 0;
      }
    }

    &-content {
      min-height: calc(100vh - 85px - 110px - 68px - 40px * 2);
      background: #ffffff;
      bottom: 0;
      padding: 40px 16px;
    }
  }
</style>
